import React from "react";

function LandingSession() {

    return (
        <div className='page-wrapper-container'>

            <div>
                <h1>LandingSession component waiting to be built!</h1>
            </div>

        </div>
    )
}


export default LandingSession
